import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { MoreInfoComponent } from 'src/app/helper/more-info/more-info.component';
import { UntypedFormBuilder, Validators, UntypedFormGroup, FormControl, UntypedFormArray, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { UtilitiesService } from 'src/app/services/utilities.service';
import { ProfilesService } from 'src/app/services/profiles.service';
import { MatDialog } from '@angular/material/dialog';
import { MatButton } from '@angular/material/button';
import { MatDatepickerInput, MatDatepickerToggle, MatDatepicker } from '@angular/material/datepicker';
import { FaIconComponent } from '@fortawesome/angular-fontawesome';
import { MatInput } from '@angular/material/input';
import { MatFormField, MatSuffix, MatLabel } from '@angular/material/form-field';
import { TitleCasePipe } from '@angular/common';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { MAT_MOMENT_DATE_ADAPTER_OPTIONS, MomentDateAdapter } from '@angular/material-moment-adapter';

export const MY_FORMATS = {
  parse: {
    dateInput: 'MM/DD/YYYY',
  },
  display: {
    dateInput: 'MM/DD/YYYY',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY',
  },
};

@Component({
  selector: 'app-influencer-application',
  templateUrl: './influencer-application.component.html',
  styleUrls: ['./influencer-application.component.scss'],
  standalone: true,
  imports: [
    FormsModule,
    ReactiveFormsModule,
    MatFormField,
    MatInput,
    FaIconComponent,
    MatSuffix,
    MatLabel,
    MatDatepickerInput,
    MatDatepickerToggle,
    MatDatepicker,
    MatButton,
    TitleCasePipe
  ],
  providers: [
    // `MomentDateAdapter` can be automatically provided by importing `MomentDateModule` in your
    // application's root module. We provide it at the component level here, due to limitations of
    // our example generation script.
    {
        provide: DateAdapter,
        useClass: MomentDateAdapter,
        deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS],
    },
    { provide: MAT_DATE_FORMATS, useValue: MY_FORMATS }
  ],
})
export class InfluencerApplicationComponent implements OnInit {
  @Input() creatorData = [];

  @Output() changePage = new EventEmitter();
  @Output() close = new EventEmitter();

  applyForm = this.fb.group({
    followerCounts: this.fb.array([]),
    influencerName: ['', Validators.required],
    realName: ['', Validators.required],
    email: ['', [Validators.required, Validators.email]],
    phone: [''],
    dateOfBirth: ['', Validators.required],
  });

  // Form array to get form validation on dynamic inputs.
  get followerCounts(): UntypedFormArray {
    return this.applyForm.get('followerCounts') as UntypedFormArray;
  }

  newFollowerCount(name): UntypedFormGroup {
    return this.fb.group({
      [name]: [this.socialsNumber[name], Validators.required],
    });
  }

  updateFollowerCountForms() {
    // Reset form array to []
    this.followerCounts.setValue([]);

    // Populate form array from selectedSocials
    for (let social of this.selectedSocials) {
      this.followerCounts.push(this.newFollowerCount(social));
    }
  }

  socialsStr = {
    instagram: '100k+',
    twitter: '100k+',
    youtube: '150k+',
    tiktok: '500k+',
  };
  socialsNumber = {
    instagram: 100000,
    twitter: 100000,
    youtube: 150000,
    tiktok: 500000,
  };
  selectedSocials = [];

  constructor(
    private dialog: MatDialog,
    private fb: UntypedFormBuilder,
    private utilities: UtilitiesService,
    private profiles: ProfilesService,
    private router: Router
  ) {}

  ngOnInit() {
    this.selectedSocials = this.creatorData

    this.updateFollowerCountForms();

    this.applyForm.patchValue({
      realName: this.utilities.accountDetails.full_name ?
        this.utilities.accountDetails.full_name :
        this.utilities.accountDetails.first_name + " " + this.utilities.accountDetails.last_name,
      email: this.utilities.accountDetails.email,
      phone: this.utilities.accountDetails.phone,
      influencerName: this.utilities.profileDetails.username,
      dateOfBirth: this.utilities.accountDetails.dob
    });
  }

  submit() {
    const {
      followerCounts,
      influencerName,
      realName,
      email,
      phone,
      dateOfBirth,
    } = this.applyForm.value;

    const body = {
      followerCounts: followerCounts,
      influencerName: influencerName,
      realName: realName,
      email: email,
      phone: phone,
      dateOfBirth: dateOfBirth,
      profileId: this.utilities.profileId,
    };

    this.profiles.createProfileLog(this.utilities.profileId, {
      object_type: 'profile',
      event: 'top-creator-application',
      action: 'application',
      receipt: body
    }).subscribe(() => {this.openMoreInfo()});

    // TODO: send to API to create ticket

  }

  openMoreInfo() {
    const dialogRef = this.dialog.open(MoreInfoComponent, {
      width: '350px',
      height: '320px',
      panelClass: 'custom-dialog',
      data: {
        // icon: 'workspace_premium',
        header: 'Request Submitted',
        message: `Your request may take 2-7 days to process. You will be notified when your request is approved.`,
        secondaryMessage: `Please do not remove any verified links from your profile during this time.`,
        closeButton: 'Back to Profiles',
      },
    });

    dialogRef.afterClosed().subscribe((result) => {
      this.router.navigate(['/profiles', {}]);
      this.close.emit()
    });
  }
}
