import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { firstValueFrom } from 'rxjs';
import { JWTTokenService } from 'src/app/services/jwttoken.service';
import { PageService } from 'src/app/services/page.service';
import { ProfilesService } from 'src/app/services/profiles.service';
import { ThemesService } from 'src/app/services/themes.service';
import { UtilitiesService } from 'src/app/services/utilities.service';
import { environment } from 'src/environments/environment';
import { LoadingComponent } from '../../../edit-page/loading/loading.component';
import { ReferralService } from 'src/app/services/referral.service';
import { NgStyle } from '@angular/common';
import { ExternalService } from 'src/app/services/external.service';

@Component({
    selector: 'app-spotlight-footer',
    templateUrl: './spotlight-footer.component.html',
    styleUrls: ['./spotlight-footer.component.scss'],
    standalone: true,
    imports: [NgStyle],
})
export class SpotlightFooterComponent  implements OnInit {
  /**
   *  Format:
   * {
   *    title: 'Social Valid',
   *    subtitle: 'Presented By',
   *    theme: '123456789900',
   *    image: {
   *      index: '1234',
   *      ext: 'png'
   *    }
   * }
   */
  @Input() spotlightData;
  @Input() themeStyles: any = {};
  @Input() previewTheme = false;
  @Input() page;
  @Input() isEdit = false;

  textColor = "#000000";

  constructor(
    private themes: ThemesService,
    private utilities: UtilitiesService,
    private router: Router,
    private jwt: JWTTokenService,
    private snackbar: MatSnackBar,
    private profiles: ProfilesService,
    private pages: PageService,
    private dialog: MatDialog,
    private referrals: ReferralService,
    private external: ExternalService
  ) {}

  ngOnInit() {
    if (this.previewTheme) {
      this.loadTheme(this.spotlightData.theme)
    }
  }

  async loadTheme(themeId) {
    const theme = await firstValueFrom(this.themes.retrieveTheme(themeId))
    if (theme) {
      this.calculateAllStyles(theme.elements);
    }
  }

  async footerLinkClicked() {
    if (this.page && !this.isEdit) {
      // Record the referral id in localStorage
      localStorage.setItem("referralId", this.spotlightData._id)

      // Record the page id in localStorage if not already in it
      if (!localStorage.getItem("referralPageId")) {
        localStorage.setItem("referralPageId", this.page._id)
      }

      if (this.spotlightData.bio_id === this.page._id) {
        // On the root page - go to SIGNUP if unauthenticated
        if (!this.jwt.isAuthenticatedUser()) {
          // Record referral click event
          this.referrals.createReferralLog(this.spotlightData._id, {
            object_type: 'referral',
            event: 'clicks',
            action: 'analytics',
          }).subscribe((data) => {
            // Navigate to sign up
            localStorage.setItem('providerMode', 'signup');
            this.router.navigateByUrl('/profiles');
          });
        } else {
          this.snackbar.open("Already signed up", "OK", {
            duration: 2000
          });
        }
      }
      else {
        // Not on the root page - go to root page
        
        const loadingOverlay = this.dialog.open(LoadingComponent, {
          maxWidth: '100vw',
          maxHeight: '100vh',
          height: '100%',
          width: '100%',
          panelClass: 'transparent-dialog',
        });

        const rootPage = await firstValueFrom(this.pages.retrievePage(this.spotlightData.bio_id, 'bio'))
        const url = await this.profiles.getPageFullUrl(rootPage, 'bio')
        console.log(url)

        loadingOverlay.close()

        this.external.openNewTab(url, "_blank")
      }
    }
  }

  isPageReferralOwner() {
    
  }

  calculateAllStyles(elements) {
    let palette = {};

    // Search for element and background palette
    for (const el of elements) {
      if (el.type === 'background') {
        palette = el.palette;
      }
    }

    // Format CSS Styles
    for (const element of elements) {
      this.themeStyles[element.type] = this.getElementStyle(element, palette);
    }
  }

  getElementStyle(element, palette) {
    const style = {};

    // If needed, apply palette colors
    for (const prop in element.properties) {
      const val = element.properties[prop];
      if (typeof val === 'object') {
        if (val.type === 'palette') {
          // Apply color from palette
          style[prop] = palette[val.color];
        }
      } else if (
        typeof val === 'string' &&
        val.includes('#') &&
        val.length === 9
      ) {
        // NgStyle ignores alpha on hex colors
        style[prop] = this.utilities.hexWithAlphaToRGBA(val);
      } else {
        style[prop] = val;
      }
    }

    // Return dict of CSS properties
    return style;
  }
}
