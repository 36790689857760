import { Component, OnInit } from '@angular/core';

import {
  ActivatedRoute,
  GuardsCheckEnd,
  GuardsCheckStart,
  NavigationCancel,
  NavigationEnd,
  Router,
  RouterModule,
  RouterOutlet,
} from '@angular/router';

import { GoogleAnalyticsService } from 'ngx-google-analytics';
import { defineCustomElements } from '@ionic/pwa-elements/loader';
import { SEOService } from './services/seo.service';
import { filter, map, mergeMap } from 'rxjs';

import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { MaterialThemeService } from './services/material-theme.service';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
  standalone: true,
  imports: [FontAwesomeModule, RouterOutlet],
  // animations: [
  //   trigger('rotateCubeToLeft', [
  //     transition('* => *', useAnimation(rotateCubeToLeft)),
  //   ]),
  // ],
})
export class AppComponent implements OnInit {
  constructor(
    private router: Router,
    private $gaService: GoogleAnalyticsService,
    private seo: SEOService,
    private route: ActivatedRoute,
    private theme: MaterialThemeService
  ) {
    defineCustomElements(window);

    window.addEventListener('touchend', (_) => {
      window.scrollTo(0, 0);
    });
  }

  loading = false;
  themeClass = 'light-theme';

  ngOnInit() {
    this.googleAnalytics();

    // Set up to listen for changes in theme.
    this.theme.themeClass.subscribe((themeClass) => {
      this.themeClass = themeClass;
    });

    // Update theme from local storage if present.
    if (localStorage.getItem('themeClass')) {
      this.theme.updateThemeClass(localStorage.getItem('themeClass'));
    }

    // Set up SEO meta tag tracking
    this.router.events
      .pipe(
        filter((event) => event instanceof NavigationEnd),
        map(() => this.route),
        map((route) => {
          while (route.firstChild) route = route.firstChild;
          return route;
        }),
        filter((route) => route.outlet === 'primary'),
        mergeMap((route) => route.data)
      )
      .subscribe((event) => {
        const { title, description, url, imageUrl, keywords } =
          event?.meta || {};

        if (title) {
          this.seo.updateTitle(title);
        }
        if (description) {
          this.seo.updateDescription(description);
        }
        if (url) {
          this.seo.updateUrl(url);
        }
        if (imageUrl) {
          this.seo.updateImage(imageUrl);
        }
        if (keywords) {
          this.seo.updateKeywords(keywords);
        }
      });
  }

  googleAnalytics() {
    // this.$gaService.pageView('/profiles', 'Profile Page'); //TODO to see if this is called multiple times
    console.log('Event logged');

    this.router.events.subscribe((event) => {
      if (event instanceof GuardsCheckStart) {
        this.loading = true;
      }
      if (
        event instanceof GuardsCheckEnd ||
        event instanceof NavigationCancel
      ) {
        this.loading = false;
      }
    });
  }

  getState(outlet) {
    return outlet.activatedRouteData.state;
  }
}

//example of sign in payload
// {
//   "sub": "0a261b19-99c0-11ec-aeb5-d59daccc9f67",
//   "type": "anonymous",
//   "iss": "https://dev.socialvalid.com",
//   "exp": 1646267462,
//   "iat": 1646181062,
//   "nbf": 1646181062
// }
