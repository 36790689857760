import { Injectable, Renderer2, RendererFactory2 } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class ScriptLoaderService {
  private renderer: Renderer2;

  constructor(rendererFactory: RendererFactory2) {
    this.renderer = rendererFactory.createRenderer(null, null);
  }

  loadScript(scriptUrl: string): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      const scriptElement = this.renderer.createElement('script');
      scriptElement.src = scriptUrl;
      scriptElement.onload = () => {
        resolve();
      };
      scriptElement.onerror = (error) => {
        reject(error);
      };
      this.renderer.appendChild(document.body, scriptElement);
    });
  }

  loadScriptWithId(scriptUrl: string, id: string, attributes?: {[key: string]: string}): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      const scriptElement = this.renderer.createElement('script');
      scriptElement.src = scriptUrl;
      scriptElement.id = id;
      scriptElement.async = true;

      // Add any additional attributes
      if (attributes) {
        Object.keys(attributes).forEach(key => {
          scriptElement.setAttribute(key, attributes[key]);
        });
      }

      scriptElement.onload = () => {
        resolve();
      };
      scriptElement.onerror = (error) => {
        reject(error);
      };
      this.renderer.appendChild(document.body, scriptElement);
    });
  }

  removeScript(id: string) {
    const scriptElement = document.getElementById(id);
    if (scriptElement) {
      scriptElement.remove();
    }
  }
}
